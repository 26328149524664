import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { showLoader } from "../../providers/actions/loader";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Button } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";

import API from "../../utils/API";

import SimpleTable from "../../Components/SimpleTable";

const tableCells = [
  {
    field: "campagne",
    label: "Campagne_parent",
  },
  {
    field: "total",
    label: "Total",
    type: "currency",
  },
  {
    field: "total_last_year",
    label: "Total_-1ans ",
    type: "currency",
  },
  {
    field: "paypal",
    label: "Paypal",
    type: "currency",
  },
  {
    field: "stripe",
    label: "Stripe",
    type: "currency",
  },
  {
    field: "iraiser",
    label: "Stripe_Iraiser",
    type: "currency",
  },
  {
    field: "gocardless",
    label: "Gocardless",
    type: "currency",
  },
  {
    field: "Espèce",
    label: "Espèce",
    type: "currency",
  },
  {
    field: "Chèque",
    label: "Chèque",
    type: "currency",
  },
  {
    field: "Virement",
    label: "Virement",
    type: "currency",
  },
  {
    field: "abonnement",
    label: "Abonnements",
  },
  {
    field: "ponctuel",
    label: "Ponctuels",
  },
  {
    field: "count",
    label: "N° dons",
  },
  {
    field: "actions",
    label: "Actions",
  },
];

const DonationByDate = ({ showLoader }) => {
  const [donations, setDonations] = useState();

  const [totals, setTotals] = useState({
    paymentsTotal: 0,
    totalCashCheck: 0,
    totalStripe: 0,
    totalIraiserStripe: 0,
    totalPaypal: 0,
    totalGocardless: 0,
  });

  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  const [reload, setReload] = useState(false);

  const handleDateFromChange = (date) => {
    setDateFrom(date);
  };
  const handleDateToChange = (date) => {
    setDateTo(date);
  };

  const handleSubmitDate = () => {
    setReload(!reload);
  };

  useEffect(() => {
    const getDonations = async () => {
      try {
        showLoader(true);

        const dateFromFormated = formatDate(dateFrom);
        const dateToFormated = formatDate(dateTo);

        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donations/campaign-parent/${dateFromFormated}/${dateToFormated}`
        );

        const data = response.data;
        setDonations(Object.values(data));

        Object.values(data).forEach((element) => {
          element["actions"] = (
            <div>
              <Link
                to={`/donations-campaign/${element.campaign_id}/${dateFromFormated}/${dateToFormated}`}
                style={{ color: "inherit" }}
              >
                <VisibilityIcon />
              </Link>
            </div>
          );
        });

        setTotals({
          paymentsTotal: response.totals.totalPaymentsVar,
          totalCashCheck: response.totals.sumCheckCash,
          totalStripe: response.totals.sumStripe,
          totalIraiserStripe: response.totals.sumIraiserStripe,
          totalPaypal: response.totals.sumPaypal,
          totalGocardless: response.totals.sumGocardless,
        });

        showLoader(false);
      } catch (error) {
        console.log(error);
        showLoader(false);
      }
    };
    getDonations().catch((error) => console.log(error));
  }, [reload, showLoader]);




  const handleExportClick = () => {
    try { 
      var csv = "";

      const headers = Object.keys(donations[0]);

      csv += headers.join(',');
      csv += "\r\n";

      // (B) ARRAY TO CSV STRING
     
      for (let payment of donations) {
        console.log(payment)
        for (let col in payment) { 
          // if(col === "campagne"){
          //   csv += payment[col] + ","; 
          // }else if(col === "total"){
          //   csv += payment[col]+ ","; 
          // }else if(col === "total_last_year"){
          //   csv += payment[col] + ","; 
          // }else if(col === "paypal"){
          //   csv += payment[col] + "," ; 
          // }else if(col === "stripe"){
          //   csv += payment[col] + ","; 
          // }else if(col === "iraiser"){
          //   csv += payment[col] + ","; 
          // }else if(col === "iraiser"){
          //   csv += payment[col] + ","; 
          // }else if(col === "metadata"){
          //   csv += " ,"; 
          // }else{
            if(col === "actions" || col === "campaign_id"){
              csv += "__,"; 
            }else{
              csv += payment[col] + ","; 
            }
        // }
        }
        csv += "\r\n";
      }

      // (C) CREATE BLOB OBJECT
      var myBlob = new Blob([csv], {type: "text/csv"});
    
      // (D) CREATE DOWNLOAD LINK
      var url = window.URL.createObjectURL(myBlob);
      var anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "donate_payments.csv";
    
      // (E) "FORCE DOWNLOAD"
      // NOTE: MAY NOT ALWAYS WORK DUE TO BROWSER SECURITY
      // BETTER TO LET USERS CLICK ON THEIR OWN
      anchor.click();
      window.URL.revokeObjectURL(url);
      anchor.remove();

    } catch (error) {

      console.error(error);

    }
  }

  return (
    <div>
      <div style={{ width: "95%", margin: "20px auto" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={9} sm={8} xs={12}>
            <Paper>
              <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Grid
                    item
                    md={5}
                    sm={6}
                    xs={12}
                    style={{ textAlign: "center" }}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline-1"
                      label="Date de début"
                      value={dateFrom}
                      onChange={(date) => handleDateFromChange(date)}
                      KeyboardButtonProps={{
                        "aria-label": "choisir une date",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sm={6}
                    xs={12}
                    style={{ textAlign: "center" }}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline-2"
                      label="Date de fin"
                      value={dateTo}
                      onChange={(date) => handleDateToChange(date)}
                      KeyboardButtonProps={{
                        "aria-label": "choisir une date",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={2}
                    sm={12}
                    xs={12}
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmitDate}
                    >
                      Valider
                    </Button>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Paper>
          </Grid>
          <Grid
            item
            md={3}
            sm={4}
            xs={12}
            style={{ height: "max-content", textAlign: "center" }}
          >
            <h3>Total</h3>
            <p style={{ color: "#f36d20", fontSize: "1.5em" }}>
              <FormatCurrency amount={totals.paymentsTotal} />
            </p>
          </Grid>
        </Grid>
      </div>
      <div style={{ width: "100%", margin: "20px auto" }}>
        {donations ? (
          <>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <h2 className="page_title">{"Don de " + formatDate(dateFrom) + " à " + formatDate(dateTo)}</h2> 
              <Button variant="contained"color="primary"onClick={handleExportClick}style={{marginBottom:'20px'}} >Exporter</Button>
            </div>
            <SimpleTable data={donations} tableCells={tableCells} />
          </>
        ) : (
          ""
        )}
      </div>
      <div style={{ width: "100%", margin: "20px auto" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={3} sm={6} xs={12} style={{ textAlign: "center" }}>
            <Paper
              elevation={3}
              style={{ padding: "10px 0", borderBottom: "solid 5px #0070ba" }}
            >
              <p>Total Paypal</p>
              <p>
                <FormatCurrency amount={totals.totalPaypal} />
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{ textAlign: "center" }}>
            <Paper
              elevation={3}
              style={{ padding: "10px 0", borderBottom: "solid 5px #5469d4" }}
            >
              <p>Total Stripe</p>
              <p>
                <FormatCurrency amount={totals.totalStripe} />
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{ textAlign: "center" }}>
            <Paper
              elevation={3}
              style={{ padding: "10px 0", borderBottom: "solid 5px #6eae43" }}
            >
              <p>Total Stripe Iraiser</p>
              <p>
                <FormatCurrency amount={totals.totalIraiserStripe} />
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{ textAlign: "center" }}>
            <Paper
              elevation={3}
              style={{ padding: "10px 0", borderBottom: "solid 5px #032550" }}
            >
              <p>Total Gocardless</p>
              <p>
                <FormatCurrency amount={totals.totalGocardless} />
              </p>
            </Paper>
          </Grid>
          <Grid item md={4} sm={6} xs={12} style={{ textAlign: "center" }}>
            <Paper
              elevation={3}
              style={{ padding: "10px 0", borderBottom: "solid 5px #f36e20" }}
            >
              <p>Total Espèce Chèques</p>
              <p>
                <FormatCurrency amount={totals.totalCashCheck} />
              </p>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

DonationByDate.propTypes = {
  showLoader: PropTypes.func.isRequired,
};

export default connect(null, { showLoader })(DonationByDate);

const FormatCurrency = ({ amount }) => {
  return new Intl.NumberFormat("fr-EU", {
    style: "currency",
    currency: "EUR",
  }).format(amount);
};

const formatDate = (date) => {
  let dateToFormat = new Date(date);
  let dd = dateToFormat.getDate();
  let mm = dateToFormat.getMonth() + 1;

  let yyyy = dateToFormat.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "-" + mm + "-" + yyyy;
};

import React, { useState, useEffect, Suspense } from "react";

import { useParams } from "react-router";

// redux
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showLoader } from "../../../providers/actions/loader";
import { setAlert } from "../../../providers/actions/alert";

import { Button, Paper, Input } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import "../main.css";
import API from "../../../utils/API";
import CurrencyFormat from "../../../Components/CurrencyFormat";
import FormatDate from "../../../Components/FormatDate";

const DonorDonations = React.lazy(() => import("./DonorDonations"));
const DonorRF = React.lazy(() => import("./DonorRF"));
const DonorDC = React.lazy(() => import("./DonorDC"));
const DonorModifs = React.lazy(() => import("./DonorModifs"));

const DonorDetails = ({ showLoader, setAlert }) => {
  let { id } = useParams();

  const loggedInUserInfo = useSelector((state) => state.isLogged);

  const [editDisabled, setEditDisabled] = useState(true);

  useEffect(() => {
    if (loggedInUserInfo) {
      setEditDisabled(loggedInUserInfo.restrictEdit);
    }
  }, [loggedInUserInfo]);

  const [lastDonationDate, setLastDonationDate] = useState("");

  // donor
  const [donor, setDonor] = useState({});

  const [donorStats, setDonorStats] = useState({});
  const getDonor = async () => {
    try {
      showLoader(true);
      const res = await API(
        "GET",
        `${process.env.REACT_APP_API_BASE_URL}/donors/${id}`
      );

      if (res.code === 200) {
        setDonor(res.data.donor);
        setDonorStats({
          donationsNumActualYear: res.data.donationsNumActualYear,
          donationsSumActualYear: res.data.donationsSumActualYear,
          lifeTimeValue: res.data.lifeTimeValue,
        });

        showLoader(false);
      } else {
        console.log(res.code, "error occured");
        setAlert(res.msg, "error");
        showLoader(false);
      }
    } catch (error) {
      console.log(error);
      setAlert("ERROR OCCURED", "error");
      showLoader(false);
    }
  };
  useEffect(() => {
    getDonor();
    return () => {
      setDonor({});
    };
  }, []);

  const [updateUpdates, setUpdateUpdates] = useState(false);

  const [updateLoaderVisible, setUpdateLoaderVisible] = useState(false);
  const handleUpdateInfoClick = async () => {
    setUpdateLoaderVisible(true);
    try {
      const res = await API(
        "PUT",
        `${process.env.REACT_APP_API_BASE_URL}/donors/${id}`,
        donor
      );

      if (res.code === 200) {
        setAlert(
          "Les informations donateurs mis à jour avec succès",
          "success"
        );
        setUpdateUpdates(!updateUpdates);
        setUpdateLoaderVisible(false);
      } else {
        setUpdateLoaderVisible(false);

        console.log(res.code, "error occured");
      }
    } catch (error) {
      setUpdateLoaderVisible(false);

      console.log(error);
    }
  };

  const handleLastDonationDateCallback = (date) => {
    setLastDonationDate(date);
  };

  const handleDeleteRGPD = async () => {
    const confirmed = window.confirm(
      "Voulez vous vraiment supprimer les données du donateur ?"
    );

    if (confirmed) {
      showLoader(true);
      const res = await API(
        "DELETE",
        `${process.env.REACT_APP_API_BASE_URL}/donors/${id}/delete-data`,
        donor
      );
      if (res.code === 200) {
        setAlert(
          "Les informations donateurs mis à jour avec succès",
          "success"
        );
        getDonor();
        setUpdateUpdates(!updateUpdates);
        showLoader(false);
      } else {
        showLoader(false);

        console.log(res.code, "error occured");
      }
    }
  };

  return (
    <div style={{ margin: "20px auto" }}>
      {donor ? (
        <>
          <div className="donor__actions__container">
            <div align="right">
              <Button
                variant="contained"
                size="small"
                color="primary"
                style={{ backgroundColor: "red" }}
                startIcon={<DeleteIcon />}
                disabled={editDisabled}
                onClick={handleDeleteRGPD}
              >
                RGPD - Supprimer les données
              </Button>
            </div>
          </div>
          <div className="donor__details__container">
            <Paper className="donor__details__padding_card">
              <h1 className="donor__details__padding_card__titles">
                <PersonIcon /> {donor.fullName}
              </h1>
              <h2 className="donor__details__padding_card__titles">
                <EmailIcon /> {donor.email}
              </h2>
            </Paper>

            <Paper className="donor__details__padding_card">
              <h3>
                Life time value :{" "}
                <CurrencyFormat amount={donorStats.lifeTimeValue} />
              </h3>
              <h3>
                <span>Dons {new Date().getFullYear()} : </span>
                <span>{donorStats.donationsNumActualYear} : </span>
                <span>
                  <CurrencyFormat amount={donorStats.donationsSumActualYear} />
                </span>
              </h3>
              <h3>
                Date du dernier dons : <FormatDate date={lastDonationDate} />
              </h3>
            </Paper>
          </div>

          <Paper className="donor__details__info_container donor__details__padding_card">
            {Object.keys(donor).map((el, index) => (
              <div key={index}>
                <b>{el} : </b>
                <div>
                  <Input
                    disabled={el === "id" ? true : false}
                    type="text"
                    fullWidth={true}
                    value={donor[el] ? donor[el] : ""}
                    onChange={(e) =>
                      setDonor({ ...donor, [el]: e.target.value })
                    }
                  />
                </div>
              </div>
            ))}
            <div align="center">
              <Button
                variant="contained"
                size="small"
                startIcon={<EditIcon />}
                onClick={handleUpdateInfoClick}
                color="primary"
                disabled={editDisabled}
              >
                Update
              </Button>
            </div>
            {updateLoaderVisible ? (
              <div className="donor__details__info_overlay">
                <CircularProgress />
              </div>
            ) : (
              ""
            )}
          </Paper>

          <Suspense fallback={<div>Chargement...</div>}>
            <DonorDonations
              donorId={id}
              lastDonationDateCallback={handleLastDonationDateCallback}
            />
          </Suspense>

          <Suspense fallback={<div>Chargement...</div>}>
            <DonorRF donorId={id} disabledEdit={editDisabled} />
          </Suspense>

          <Suspense fallback={<div>Chargement...</div>}>
            <DonorDC donorId={id} disabledEdit={editDisabled} />
          </Suspense>

          <Suspense fallback={<div>Chargement...</div>}>
            <DonorModifs donorId={id} updateConponent={updateUpdates} />
          </Suspense>
        </>
      ) : (
        "Donor not found"
      )}
    </div>
  );
};

DonorDetails.propTypes = {
  showLoader: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default connect(null, { showLoader, setAlert })(DonorDetails);

import React, { useEffect, useState } from "react";

import API from "../../utils/API";

import { connect } from "react-redux";
import { showLoader } from "../../providers/actions/loader";
import PropTypes from "prop-types";
import SimpleTable from "../../Components/SimpleTable";
import SimplePagination from "../../Components/SimplePagination";
import NewCampaign from "./NewCampaign";
import NewParentCampaign from "./NewPrentCampaign";

import {
  Grid,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import UpdateIcon from "@material-ui/icons/Update";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

import "./main.css";

const tableCells = [
  {
    field: "name",
    label: "Campagne",
  },
  {
    field: "campaignParent.name",
    label: "Campagne Parent",
  },
  {
    field: "createdAt",
    label: "Date de création",
    type: "date",
  },
  {
    field: "actions",
    label: "Action",
  },
];

const Campaigns = ({ showLoader }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsParent, setCampaignsParent] = useState([]);

  const [reloadCampaigns, setReloadCampaigns] = useState(false);
  const [reloadCampaignsParent, setReloadCampaignsParent] = useState(false);

  const [pagesTotalNum, setPagesTotalNum] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const pageSize = 20;

  const [campName, setCampName] = useState("");
  const [campParentName, setCampParentName] = useState("");
  const [campaignParentId, setCampaignParentId] = useState("");

  const [provider, setProvider] = useState("");

  const [searchKey, setSearchKey] = useState("");
  const [resetSearch, setResetSearch] = useState(false);
  const [resetSearchVisible, setResetSearchVisible] = useState(false);

  const pageNumCallback = (num) => {
    setPageNum(parseInt(num));
  };

  useEffect(() => {
    const getAllCampaignsParent = async () => {
      try {
        showLoader(true);

        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/campaigns-parent`
        );

        setCampaignsParent(response.data);

        showLoader(false);
      } catch (error) {
        console.log(error);

        alert("ERROR OCCURED");
        showLoader(false);
      }
    };

    getAllCampaignsParent();

    return () => {
      setCampaignsParent([]);
    };
  }, [reloadCampaignsParent, showLoader]);

  useEffect(() => {
    const getAllCampaigns = async () => {
      try {
        showLoader(true);

        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/campaigns?pageNum=${pageNum}&pageSize=${pageSize}`
        );

        response.data.forEach((campaign) => {
          campaign["actions"] = (
            <IconButton
              aria-label="update"
              style={{ color: "inherit" }}
              onClick={() =>
                handleDialogOpencallback(true, {
                  campName: campaign.name,
                  campId: campaign.id,
                  campGivenName: campaign.campaignParent.name,
                  campGivenId: campaign.campaignParent.id,
                })
              }
            >
              <UpdateIcon />
            </IconButton>
          );
          campaign["id"] = `#${campaign.id}`;
        });

        setCampaigns(response.data);

        setPagesTotalNum(Math.ceil(response.total / pageSize));

        showLoader(false);
      } catch (error) {
        console.log(error);

        alert("ERROR OCCURED");
        showLoader(false);
      }
    };

    getAllCampaigns();

    return () => {
      setCampaigns([]);
    };
  }, [pageNum, reloadCampaigns, resetSearch, showLoader]);

  const newCampaignCallback = ({ dialogOpen, rereload }) => {
    setProvider("");
    setCampName("");
    setCampParentName("");
    setCampaignParentId("");

    setDialogOpen(dialogOpen);

    if (rereload) {
      setReloadCampaigns(!reloadCampaigns);
    }
  };

  const newCPCallback = ({ dialogOpen, rereload }) => {
    setDialogParentOpen(dialogOpen);

    if (rereload) {
      setReloadCampaignsParent(!reloadCampaignsParent);
    }
  };

  const [dialogParentOpen, setDialogParentOpen] = useState(false);
  const handleDialogParentOpen = () => {
    setDialogParentOpen(true);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogOpencallback = (open, params) => {
    if (params) {
      setCampName(params.campName);
      setCampParentName(params.campGivenName);
      setCampaignParentId(params.campGivenId);
    }
    setDialogOpen(open);
    setProvider("campaignOthers");
  };

  const handleDeleteCampaignParent = async (id) => {
    let res = window.confirm("vous voulez supprimer cette campagne?");

    if (res) {
      try {
        const response = await API(
          "DELETE",
          `${process.env.REACT_APP_API_BASE_URL}/campaigns-parent/${id}`
        );

        if (response.code === 200) {
          setReloadCampaignsParent(!reloadCampaignsParent);
        }
      } catch (error) {
        console.log(error);
        alert("ERROR OCCURED");
      }
    }
  };

  const handleSearchClick = async () => {
    if (searchKey) {
      try {
        showLoader(true);
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/campaigns/search?key=${searchKey}`
        );

        response.data.forEach((campaign) => {
          campaign["actions"] = (
            <IconButton
              aria-label="update"
              style={{ color: "inherit" }}
              onClick={() =>
                handleDialogOpencallback(true, {
                  campName: campaign.name,
                  campId: campaign.id,
                  campGivenName: campaign.campaignParent.name,
                  campGivenId: campaign.campaignParent.id,
                })
              }
            >
              <UpdateIcon />
            </IconButton>
          );
          campaign["id"] = `#${campaign.id}`;
        });

        setCampaigns(response.data);
        setResetSearchVisible(true);
        showLoader(false);
      } catch (error) {
        alert("ERROR OCCURED");
        console.log(error);
        alert("ERROR OCCURED");
        showLoader(false);
      }
    }
  };

  const handleResetSearchClick = () => {
    setResetSearch(!resetSearch);
    setResetSearchVisible(false);
  };

  return (
    <div className="container__page">
      <Grid container direction="row" alignItems="center">
        <Grid item md={6} sm={6} xs={12}>
          <h1 className="page_title">Campagnes Parent</h1>
        </Grid>

        <Grid item md={6} sm={6} xs={12} style={{ textAlign: "right" }}>
          <Button
            color="primary"
            onClick={handleDialogParentOpen}
            startIcon={<AddIcon />}
          >
            Campagne Parent
          </Button>
        </Grid>
      </Grid>

      <div align="center">
        {campaignsParent
          ? campaignsParent.map((parent, index) => (
              <div className="campaign__parent_badges" key={index}>
                <div
                  className="delete_cp_overlay"
                  onClick={() => handleDeleteCampaignParent(parent.id)}
                >
                  <DeleteIcon />
                </div>
                {parent.name}
              </div>
            ))
          : ""}
      </div>

      <Grid container direction="row" alignItems="center">
        <Grid item md={6} sm={6} xs={12}>
        </Grid>

        <Grid item md={6} sm={6} xs={12} style={{ textAlign: "right" }}>
          <Button
            color="primary"
            onClick={handleDialogOpen}
            startIcon={<AddIcon />}
          >
            Campagne
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="center">
        <Grid item md={6} sm={6} xs={12}>
          <h2 className="page_title">Campagnes</h2>
        </Grid>

        <Grid item md={6} sm={6} xs={12} style={{ textAlign: "right" }}>
        <FormControl>
          <InputLabel htmlFor="standard-adornment-search">
            Rechercher
          </InputLabel>
          <Input
            id="standard-adornment-search"
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="Search" onClick={handleSearchClick}>
                  {<SearchIcon />}
                </IconButton>
                {resetSearchVisible ? (
                  <IconButton
                    aria-label="Reset Search"
                    onClick={handleResetSearchClick}
                  >
                    {<CloseIcon />}
                  </IconButton>
                ) : (
                  ""
                )}
              </InputAdornment>
            }
          />
        </FormControl>
        </Grid>
      </Grid>

      {/* <div align="right" style={{ marginBottom: "20px" }}>
        <FormControl>
          <InputLabel htmlFor="standard-adornment-search">
            Rechercher
          </InputLabel>
          <Input
            id="standard-adornment-search"
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="Search" onClick={handleSearchClick}>
                  {<SearchIcon />}
                </IconButton>
                {resetSearchVisible ? (
                  <IconButton
                    aria-label="Reset Search"
                    onClick={handleResetSearchClick}
                  >
                    {<CloseIcon />}
                  </IconButton>
                ) : (
                  ""
                )}
              </InputAdornment>
            }
          />
        </FormControl>
      </div> */}

      <SimpleTable data={campaigns} tableCells={tableCells} />
      <SimplePagination
        pageNum={pageNum}
        pagesTotal={pagesTotalNum}
        paginationCallback={pageNumCallback}
      />

      {dialogOpen ? (
        <NewCampaign
          parentCallback={newCampaignCallback}
          visible={dialogOpen}
          campaignName={campName}
          campaignParentName={campParentName}
          campaignParentId={campaignParentId}
          provider={provider}
        />
      ) : (
        ""
      )}

      <NewParentCampaign
        callback={newCPCallback}
        visibleparent={dialogParentOpen}
      />
    </div>
  );
};

Campaigns.propTypes = {
  showLoader: PropTypes.func.isRequired,
};

export default connect(null, { showLoader })(Campaigns);

import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { showLoader } from "../../providers/actions/loader";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";

import SimpleTable from "../../Components/SimpleTable";
import SimplePagination from "../../Components/SimplePagination";

import API from "../../utils/API";

const recordPerPage = 20;

const tableCells = [
  {
    field: "paymentDate",
    label: "Date",
    type: "date",
  },
  {
    field: "campaign",
    label: "Campagne",
  },
  {
    field: "campaignParent",
    label: "Campagne_parent",
  },
  {
    field: "amount",
    label: "Don",
    type: "currency",
  },
  {
    field: "geteway",
    label: "passerelle",
  },
  {
    field: "regularity",
    label: "Régularité",
  },
  {
    field: "email",
    label: "Donor_email",
  },
  {
    field: "actions",
    label: "Actions",
  },
];

const Donations = ({ history, showLoader }) => {
  const isLogged = useSelector((state) => state.isLogged);
  const [restrictAccess, setRestrictAccess] = useState({
    restrictAdd: true,
    restrictEdit: true,
    restrictDelete: true,
    restrictShow: false,
  });

  useEffect(() => {
    if (isLogged) {
      setRestrictAccess({
        restrictAdd: isLogged.restrictAdd,
        restrictEdit: isLogged.restrictEdit,
        restrictDelete: isLogged.restrictDelete,
        restrictShow: isLogged.restrictShow,
      });
    }
    return () => {
      setRestrictAccess({
        restrictAdd: true,
        restrictEdit: true,
        restrictDelete: true,
        restrictShow: false,
      });
    };
  }, [isLogged]);

  const [donations, setDonations] = useState([]);

  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);

  useEffect(() => {
    const getDonations = async (pageNum = 1) => {
      showLoader(true);

      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donations?pageNum=${pageNum}&pageSize=${recordPerPage}`
        );

        if (response.code === 200) {
          if (response.data.length > 0) {
            response.data.forEach((donation) => {
              donation["actions"] = (
                <Link
                  to={`/donation/${donation.id}`}
                  style={{ color: "inherit" }}
                >
                  <VisibilityIcon />
                </Link>
              );
            });

            setDonations(response.data);
            setTotalRecords(Math.ceil(response.total / recordPerPage));
          }
        }

        showLoader(false);
      } catch (error) {
        console.log(error);
        alert("ERROR OCCURED")

        showLoader(false);
      }
    };
    getDonations(page);
  }, [page, showLoader]);

  const handlePaginationCallback = (pageNumCallback) => {
    setPage(pageNumCallback);

    history.push(`/donations/${pageNumCallback}`);
  };

  return (
    <div style={{ width: "95%", margin: "20px auto" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1 className="page_title">Liste des Dons</h1>
        <Link
          to={restrictAccess.restrictAdd ? "#" : "/donation"}
          style={{ textDecoration: "none" }}
        >
          <Button
            disabled={restrictAccess.restrictAdd}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Ajouter un don
          </Button>
        </Link>
      </Grid>
      <SimpleTable data={donations} tableCells={tableCells} />
      <SimplePagination
        pageNum={page}
        pagesTotal={totalRecords}
        paginationCallback={handlePaginationCallback}
      />
    </div>
  );
};

Donations.propTypes = {
  showLoader: PropTypes.func.isRequired,
};

export default connect(null, { showLoader })(Donations);

import React, { useEffect, useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";

import SimpleTable from "../../Components/SimpleTable";
import API from "../../utils/API";
import { Button, Paper } from "@material-ui/core";

const recordPerPage = 5;

const tableCells = [
  {
    field: "paymentDate",
    label: "Date",
    type: "date",
  },
  {
    field: "campaign",
    label: "Campagne",
  },
  {
    field: "amount",
    label: "Don",
    type: "currency",
  },
  {
    field: "actions",
    label: "Actions",
  },
];
const LatestDonations = () => {
  const [donations, setDonations] = useState([]);

  useEffect(() => {
    const getDonations = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donations?pageNum=1&pageSize=${recordPerPage}`
        );

        if (response.code === 200) {
          if (response.data.length > 0) {
            response.data.forEach((donation) => {
              donation["actions"] = (
                <Link
                  to={`/donation/${donation.id}`}
                  style={{ color: "inherit" }}
                >
                  <VisibilityIcon />
                </Link>
              );
            });

            setDonations(response.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDonations();
    return () => {
      setDonations([]);
    }
  }, []);
  return (
    <Paper style={{ height: "100%" }}>
    <div className="container__inner">
      <h3 className="block_title">Derniers Dons</h3>
      <SimpleTable data={donations} tableCells={tableCells} />
      <div align="right" style={{ marginTop: "10px" }}>
        <Button variant="contained" color="primary" size="small">
          <Link to="/donations" style={{ color: "inherit" }}>
            Voir plus
          </Link>
        </Button>
      </div>
    </div>
    </Paper>
  );
};

export default LatestDonations;

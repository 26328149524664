import React, { useState, useEffect } from "react";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import logo from "../Logo-life-min.png";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";

import imageRepas from "../assets/images/loginBg.jpg";

import API from "../utils/API";

// redux
import { loggIn } from "../providers/actions";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../Components/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
}));

const Login = ({ history }) => {
  const isLoggedIn = useSelector((state) => state.isLogged.isLoggedIn);

  const dispatch = useDispatch();
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("error");

  const handleEmailchange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordchange = (event) => {
    setPassword(event.target.value);
  };

  const [darkState, setDarkState] = useState(false);

  const palletType = darkState ? "dark" : "light";

  const theme = createTheme({
    palette: {
      type: palletType,
      primary: {
        light: "#ff9f50",
        main: "#f36e20",
        dark: "#b93e00",
        contrastText: "#fff",
      },
      secondary: {
        light: "#6d6d6d",
        main: "#424242",
        dark: "#1b1b1b",
        contrastText: "#ffffff",
      },
    },
  });

  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      setDarkState(true);
    } else {
      setDarkState(false);
    }

    setIsLoading(true);
    const getParameter = (key) => {
      // Address of the current window
      const address = window.location.search;

      // Returns a URLSearchParams object instance
      const parameterList = new URLSearchParams(address);

      // Returning the respected value associated
      // with the provided key
      return parameterList.get(key);
    };

    // Gets the value associated with the key "ie"
    const token = getParameter("redirecttoken");

    if (token && token.length > 0) {
      var base64Payload = token.split(".")[1];
      var payload = Buffer.from(base64Payload, "base64");
      const loggedinuser = JSON.parse(payload.toString());
      if (
        loggedinuser.roles.includes("ROLE_ADMIN") ||
        loggedinuser.roles.includes("ROLE_COMPTA") ||
        loggedinuser.roles.includes("ROLE_RD")
      ) {
        setIsLoading(false);
        dispatch(loggIn({ token: token }));
      } else {
        setIsLoading(false);
        alert("You are not authorized to access this app");
      }
    } else {
      setIsLoading(false);
    }

    if (isLoggedIn) {
      setTimeout(() => {
        window.location.href = "/";
      }, 100);
    }
  }, [isLoggedIn, dispatch, history]);

  const handleForm = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setSeverity("error");
      setAlertMsg("Verifiez vos identifiants");
      setOpen(true);
    } else {
      const response = await API(
        "POST",
        `${process.env.REACT_APP_NEW_BASEURL_APIS}/api/login_check`,
        {
          username: email,
          password: password,
        }
      );

      if (response.token) {
        setSeverity("success");
        setAlertMsg("Bienvenue");
        setOpen(true);

        dispatch(loggIn(response));
        setTimeout(() => {
          history.push("/");
        }, 2000);
      } else {
        setSeverity("error");
        setAlertMsg("Email ou mot de passe non valide");
        setOpen(true);
        return false;
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Loading open={isLoading} />
      <CssBaseline />
      <div className="login__container">
        <div className="login__form">
          <div>
            <img
              src={logo}
              alt="logo"
              title="Logo"
              height="120"
              style={{ margin: "0 auto 50px", display: "flex" }}
            />
            <h1 align="center">Login</h1>
            <form className={classes.root} noValidate autoComplete="on">
              <TextField
                fullWidth
                id="email"
                type="email"
                label="Email"
                value={email}
                onChange={handleEmailchange}
                autoComplete="email"
              />
              <TextField
                fullWidth
                id="password"
                type="password"
                label="Password"
                value={password}
                onChange={handlePasswordchange}
                autoComplete="current-password"
              />
              <Button
                fullWidth
                variant="contained"
                style={{ backgroundColor: "#f36e20", color: "#fff" }}
                type="submit"
                onClick={handleForm}
              >
                Login
              </Button>
            </form>
            <Collapse in={open}>
              <Alert
                severity={severity}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {alertMsg}
              </Alert>
            </Collapse>
          </div>
        </div>
        <div
          className="login__bg_image"
          style={{
            position: 'relative',
            backgroundImage: "url(" + imageRepas + ")",
            backgroundSize: "cover",
            backgroundPosition: "right",
          }}
        ></div>
      </div>
    </ThemeProvider>
  );
};
export default Login;

import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import API from "../../utils/API";
import { Paper } from "@material-ui/core";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      maxHeight: 50,
      labels: {
        useBorderRadius: true,
        borderRadius: 3,
        boxHeight: 16,
        color: "#333",
      },
    },
    tooltip: {
      backgroundColor: "#ffffff",
      titleColor: "#000000",
      bodyColor: "#000000",
      borderColor: "#cbd5e1",
      borderWidth: 1,
    },
    title: {
      display: false,
      text: "Donateurs par ans",
    },
  },
};

const DonorByYear = () => {
  const [donorsByYearLabels, setDonorsByYearLabels] = useState([]);
  const [donorsByYearValues, setDonorsByYearValues] = useState([]);

  useEffect(() => {
    const getDonorsByYear = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_NEW_BASEURL_APIS}/dashboard/donor-by-year`
        );

        const numbers = response.data;

        var labels = [];
        var values = [];

        Object.keys(numbers).forEach((key) => {
          labels.push(key);
          values.push(numbers[key]);
        });

        setDonorsByYearLabels(labels);
        setDonorsByYearValues(values);
      } catch (error) {
        console.log(error);
      }
    };
    getDonorsByYear();
    return () => {
      setDonorsByYearLabels([]);
      setDonorsByYearValues([]);
    };
  }, []);

  return (
    <Paper>
      <div className="container__inner">
        <h3 className="block_title">Donateurs actifs par années</h3>
        <Bar
          options={options}
          data={{
            labels: donorsByYearLabels,
            datasets: [
              {
                label: "Donateurs",
                backgroundColor: "rgb(243 110 32 / 80%)",
                borderRadius: 4,
                data: donorsByYearValues,
              },
            ],
          }}
        />
      </div>
    </Paper>
  );
};

export default DonorByYear;

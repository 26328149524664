import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { showLoader } from "../../providers/actions/loader";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import {
  FormControl,
  Input,
  InputLabel,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";

import SimplePagination from "../../Components/SimplePagination";
import { useParams, useHistory } from "react-router";
import SimpleTable from "../../Components/SimpleTable";
import CurrencyFormat from "../../Components/CurrencyFormat";

import API from "../../utils/API";

const recordPerPage = 10;

const tableCells = [
  {
    label: "#",
    field: "id",
  },
  {
    label: "Nom Complet",
    field: "fullName",
  },
  {
    label: "Email",
    field: "email",
  },
  {
    label: "LifeTime Value",
    field: "lifeTimeValue",
  },
  {
    label: "nbre Dons " + new Date().getFullYear(),
    field: "donationsNumActualYear",
  },
  {
    label: "Dons " + new Date().getFullYear(),
    field: "donationsSumActualYear",
  },
  {
    label: "Actions",
    field: "actions",
  },
];

const Donors = ({ showLoader }) => {
  let { page } = useParams();

  let history = useHistory();

  const [searchKey, setSearchKey] = useState("");
  const [resetSearch, setResetSearch] = useState(false);
  const [resetSearchVisible, setResetSearchVisible] = useState(false);

  const [pageNum, setPageNum] = useState(page ? page : 1);

  const [pagesTotal, setPagesTotal] = useState(1);

  // const [recordPerPage, setRecordPerPage] = useState(10);

  const [donors, setDonors] = useState([]);

  useEffect(() => {
    showLoader(true);
    const getDonors = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donors?pageNum=${pageNum}&pageSize=${recordPerPage}`
        );

        res.data.forEach((donor) => {
          donor["actions"] = (
            <Link
              to={`/donors/${donor.id}/details`}
              style={{ color: "inherit" }}
            >
              <VisibilityIcon />
            </Link>
          );

          donor["donationsSumActualYear"] = (
            <CurrencyFormat amount={donor.donationsSumActualYear} />
          );
          donor["lifeTimeValue"] = (
            <CurrencyFormat amount={donor.lifeTimeValue} />
          );

          donor["id"] = `#${donor.id}`;
        });

        setDonors(res.data);

        setPageNum(res.pageNum);

        setPagesTotal(Math.ceil(res.total / recordPerPage));

        showLoader(false);
      } catch (error) {
        console.log(error);
        showLoader(false);
      }
    };

    getDonors();

    return () => {
      setDonors([]);

      setPagesTotal(1);
    };
  }, [resetSearch, pageNum, showLoader]);

  const handlePaginationCallback = (pageNumCallback) => {
    setPageNum(pageNumCallback);

    history.push(`/donors/${pageNumCallback}`);
  };

  const handleSearchClick = async () => {
    if (searchKey) {
      try {
        showLoader(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donors/search?key=${searchKey}`
        );

        if (res.code === 200) {
          res.data.forEach((donor) => {
            donor["actions"] = (
              <Link
                to={`/donors/${donor.id}/details`}
                style={{ color: "inherit" }}
              >
                <VisibilityIcon />
              </Link>
            );

            donor["donationsSumActualYear"] = "-";

            donor["donationsNumActualYear"] = "-";

            donor["lifeTimeValue"] = "-";

            donor["email"] = donor.user ? donor.user.email : "";

            donor["id"] = `#${donor.id}`;
          });

          setDonors(res.data);
          setResetSearchVisible(true);
          showLoader(false);
        } else if (res.code === 404) {
          alert("aucun donateur avec cet email");
          console.log("error", res);
          showLoader(false);
        } else {
          console.log("error", res);
          showLoader(false);
        }
      } catch (error) {
        alert("ERROR OCCURED");
        console.log(error);
        showLoader(false);
      }
    }
  };

  const handleResetSearchClick = () => {
    setResetSearch(!resetSearch);
    setResetSearchVisible(false);
  };
  return (
    <div className="container__page">
      <div style={customStyle.pageHeader}>
        <h1 className="page_title" style={customStyle.pageTitle}>Donateurs</h1>

        <div align="right">
          <FormControl>
            <InputLabel htmlFor="standard-adornment-search">
              Rechercher
            </InputLabel>
            <Input
              id="standard-adornment-search"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="Search" onClick={handleSearchClick}>
                    {<SearchIcon />}
                  </IconButton>
                  {resetSearchVisible ? (
                    <IconButton
                      aria-label="Reset Search"
                      onClick={handleResetSearchClick}
                    >
                      {<CloseIcon />}
                    </IconButton>
                  ) : (
                    ""
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>

      <div>
        <SimpleTable data={donors} tableCells={tableCells} />

        <SimplePagination
          pageNum={pageNum}
          pagesTotal={pagesTotal}
          paginationCallback={handlePaginationCallback}
        />
      </div>
    </div>
  );
};

Donors.propTypes = {
  showLoader: PropTypes.func.isRequired,
};

export default connect(null, { showLoader })(Donors);

const customStyle = {
  pageHeader: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  pageTitle: {
    display: "inline-block",
  },
};

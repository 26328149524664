import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Search from "../Components/Search";

import orange from "@material-ui/core/colors/orange";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
} from "@material-ui/core";
// import GestureIcon from '@material-ui/icons/Gesture';
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeIcon from "@material-ui/icons/Home";
import EuroIcon from "@material-ui/icons/Euro";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import FolderIcon from '@material-ui/icons/Folder';
import EventNoteIcon from "@material-ui/icons/EventNote";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import EventIcon from "@material-ui/icons/Event";
import BusinessIcon from '@material-ui/icons/Business';

import AccountCircle from "@material-ui/icons/AccountCircle";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";

import ReceiptIcon from "@material-ui/icons/Receipt";
import GroupIcon from '@material-ui/icons/Group';
import PublicIcon from '@material-ui/icons/Public';

import { createTheme, makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { loggOut } from "../providers/actions";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#f36e20",
  },
  drawerHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    paddingLeft: "16px",
    fontSize: "1.2rem",
    color: "white",
    fontWeight: "bold",
  },
  content: {
    marginTop: "40px",
    flexGrow: 1,
    width: "100%",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
const Header = ({ darkCallback, drawerCallback }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [camMenuopen, setCamMenuopen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openProfileMenu = Boolean(anchorEl);

  const [open, setOpen] = useState(true);
  const [darkState, setDarkState] = useState(false);
  const palletType = darkState ? "dark" : "light";

  const theme = createTheme({
    palette: {
      type: palletType,
      primary: {
        light: "#ff9f50",
        main: "#f36e20",
        dark: "#b93e00",
        contrastText: "#fff",
      },
      secondary: {
        light: "#6d6d6d",
        main: "#424242",
        dark: "#1b1b1b",
        contrastText: "#ffffff",
      },
    },
  });
  const handleThemeChange = () => {
    setDarkState(!darkState);
    darkCallback(!darkState);
    darkState
      ? localStorage.setItem("theme", "light")
      : localStorage.setItem("theme", "dark");
  };

  useEffect(() => {
    if (window.innerWidth < 991) {
      setOpen(false);
      drawerCallback(false);
    }
    if (localStorage.getItem("theme") === "dark") {
      setDarkState(true);
      darkCallback(true);
    } else {
      setDarkState(false);
      darkCallback(false);
    }
  }, [darkCallback, drawerCallback]);

  const handleDrawerOpen = () => {
    setOpen(true);
    drawerCallback(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    drawerCallback(false);
  };

  const handleCamMenuClick = () => {
    setCamMenuopen(!camMenuopen);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogoutClick = () => {
    dispatch(loggOut());
    setAnchorEl(null);
  };
  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{ display: "flex" }}
      >
        <Toolbar>
          <IconButton
            color="secondary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          <IconButton
            onClick={handleDrawerClose}
            className={clsx(classes.menuButton, !open && classes.hide)}
            style={{ color: "back" }}
          >
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>

          <Typography
            style={{ color: orange[50], fontWeight: "bold" }}
            variant="h5"
            className={classes.title}
          >
            <Search />
          </Typography>

          
          {darkState ? (
            <IconButton
              onClick={handleThemeChange}
              aria-label="Light theme"
              title="Light Mode"
            >
              <Brightness7Icon />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleThemeChange}
              aria-label="Dark theme"
              title="Dark Mode"
              style={{ color: "inherit" }}
            >
              <Brightness4Icon />
            </IconButton>
          )}

          <IconButton
            aria-label="current user"
            aria-controls="menu-appbar-profile"
            aria-haspopup="true"
            onClick={handleProfileMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar-profile"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openProfileMenu}
            onClose={handleClose}
          >
            <Link to="/profile" style={{textDecoration: "none", color: "inherit"}}>
              <MenuItem> Profil </MenuItem>
            </Link>
            <MenuItem onClick={handleLogoutClick}>Se déconnecter</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        style={{ display: "block", zIndex: "1" }}
      >
        <div className={classes.drawerHeader}>
          <span>LIFE ONG</span>
        </div>
        <List>
          <Link to="/" style={navStyle}>
            <ListItem button key="Dashboard" selected={window.location.pathname === '/'} >
              <ListItemIcon>
                <HomeIcon style={{color: 'white'}} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>

          <Link to="/donations" style={navStyle}>
            <ListItem button key="Dons List" selected={window.location.pathname === '/donations'} >
              <ListItemIcon>
                <EuroIcon  style={{color: 'white'}}/>
              </ListItemIcon>
              <ListItemText primary="Dons" />
            </ListItem>
          </Link>
          <Link to="/donations-by-date" style={navStyle}>
            <ListItem button key="Dons Date" selected={window.location.pathname === '/donations-by-date'} >
              <ListItemIcon>
                <EventNoteIcon  style={{color: 'white'}} />
              </ListItemIcon>
              <ListItemText primary="Dons par date" />
            </ListItem>
          </Link>
          <Link to="/donations-by-regularity" style={navStyle}>
            <ListItem button key="Donantions by Regularity" selected={window.location.pathname === '/donations-by-regularity'} >
              <ListItemIcon>
                <LocalOfferIcon style={{color: 'white'}} />
              </ListItemIcon>
              <ListItemText primary="Dons par régularité" />
            </ListItem>
          </Link>

          <ListItem button key="Campagnes" onClick={handleCamMenuClick} style={{color: 'white'}}  selected={window.location.pathname === '/campaigns' || window.location.pathname === '/campaigns/autres'} >
            <ListItemIcon>
              <FolderIcon  style={{color: 'white'}} />
            </ListItemIcon>
            <ListItemText primary="Campagnes" />
            {camMenuopen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={camMenuopen} timeout="auto">
            <List component="div" disablePadding>
              <Link to="/campaigns" style={navStyle}>
                <ListItem button key="listedesCampagnes">
                  <ListItemText
                    primary="Liste des Campagnes"
                    style={{ paddingLeft: "5px" }}
                  />
                </ListItem>
              </Link>
              <Link to="/campaigns/autres" style={navStyle}>
                <ListItem button key="CampagneAutres">
                  <ListItemText
                    primary="Campagne Autres"
                    style={{ paddingLeft: "5px" }}
                  />
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Link to="/fiscal-receipts" style={navStyle}>
            <ListItem button key="RF" selected={window.location.pathname === '/fiscal-receipts'} >
              <ListItemIcon>
                <ReceiptIcon  style={{color: 'white'}} />
              </ListItemIcon>
              <ListItemText primary="Reçus fiscaux" />
            </ListItem>
          </Link>
          <Link to="/donors" style={navStyle}>
            <ListItem button key="donateurs" selected={window.location.pathname === '/donors'} >
              <ListItemIcon>
                <GroupIcon style={{color: 'white'}} />
              </ListItemIcon>
              <ListItemText primary="Donateurs" />
            </ListItem>
          </Link>

          <Link to="/companies-donations" style={navStyle}>
            <ListItem button key="Companies" selected={window.location.pathname === '/companies-donations'} >
              <ListItemIcon>
                <BusinessIcon style={{color: 'white'}} />
              </ListItemIcon>
              <ListItemText primary="Dons Entreprises" />
            </ListItem>
          </Link>
          
          <Link to="/refund-requests" style={navStyle}>
            <ListItem button key="DemandesDeRemboursement" selected={window.location.pathname === '/refund-requests'} >
              <ListItemIcon>
                <MoneyOffIcon style={{color: 'white'}} />
              </ListItemIcon>
              <ListItemText primary="Demandes de remboursement" />
            </ListItem>
          </Link>

          <Link to="/exporter" style={navStyle}>
            <ListItem button key="export" selected={window.location.pathname === '/exporter'} >
              <ListItemIcon>
                <CloudDownloadIcon style={{color: 'white'}} />
              </ListItemIcon>
              <ListItemText primary="Export" />
            </ListItem>
          </Link>

          {/* <Link to="/donations-tracking" style={navStyle}>
            <ListItem button key="donationsTracking">
              <ListItemIcon>
                <PublicIcon />
              </ListItemIcon>
              <ListItemText primary="Suivi de dons" />
            </ListItem>
          </Link> */}
        </List>
        <List  style={{position: 'absolute', bottom: '0', width: '100%', padding: '16px'}}>
          <a href="https://life-ong.org" target="_blank" rel="noopener noreferrer" style={{color: "white", textDecoration: "none", display: 'flex', alignItems: 'center'}}>
            <PublicIcon style={{color: 'white'}} /> <span style={{marginLeft: '10px'}}>LIFE ONG</span>
          </a>
        </List>
      </Drawer>
    </>
  );
};

export default Header;

const navStyle = {
  color: "white",
  textDecoration: "none",
};

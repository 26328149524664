import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { showLoader } from "../../../providers/actions/loader";
import { setAlert } from "../../../providers/actions/alert";
import PropTypes from "prop-types";

import {
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Paper,
  Grid,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";

import { makeStyles } from "@material-ui/core/styles";

import API from "../../../utils/API";
import SearchDonorPopup from "./SearchDonorPopup";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
}));

const DonationCreate = ({ showLoader, setAlert }) => {
  const classes = useStyles();

  const [numCheckDisplay, setNumCheckDisplay] = useState(false);
  const [bankNameDisplay, setBankNameDisplay] = useState(false);

  const [parentCampaigns, setParentCampaigns] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const getAllParentCampaigns = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/campaigns-parent`
        );

        const parentcampaigns = response.data;

        setParentCampaigns(parentcampaigns);
      } catch (error) {
        alert("ERROR OCCURED");
        console.log(error);
      }
    };
    const getAllCampaigns = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/campaigns/all`
        );

        setCampaigns(response.data);
      } catch (error) {
        alert("ERROR OCCURED");
        console.log(error);
      }
    };

    getAllParentCampaigns();
    getAllCampaigns();
  }, []);

  const [paymentDate, setPaymentDate] = useState(new Date());
  const handleDateChange = (date) => {
    setPaymentDate(date);
    setManualDonation({
      ...manualDonation,
      paymentDate: date.toString(),
    });
  };

  const [selectedCampaign, setSelectedCampaign] = useState("");

  const handelSubmitDonation = async (e) => {
    e.preventDefault();

    if (
      !manualDonation.amount ||
      !manualDonation.paymentMode ||
      !manualDonation.campaign ||
      !manualDonation.campaignParent ||
      !manualDonation.campaignParentId ||
      !manualDonation.firstName ||
      !manualDonation.lastName
    ) {
      alert("ERROR: Veillez remplir tous les champs obligatoires *");
      return;
    }

    var dateToFormat = new Date(manualDonation.paymentDate);
    try {
      showLoader(true);
      const response = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/donations`,
        {
          amount: manualDonation.amount,
          paymentMode: manualDonation.paymentMode,
          checkNumber: manualDonation.checkNum,
          transferBankName: manualDonation.bankName,

          paymentDate: `${dateToFormat.getFullYear()}-${
            dateToFormat.getMonth() + 1
          }-${dateToFormat.getDate()} ${dateToFormat.getHours()}:${dateToFormat.getMinutes()}`,
          campaignName: manualDonation.campaign,
          givenCampaignName: manualDonation.campaignParent,
          givenCampaignId: manualDonation.campaignParentId,

          donorFirstName: manualDonation.firstName,
          donorLastName: manualDonation.lastName,
          donorEmail: manualDonation.email,
          donorCompany: manualDonation.company,
          donorAddress: manualDonation.address,
          donorCity: manualDonation.city,
          donorCountry: manualDonation.country,
          donorPhone: manualDonation.phoneNumber,
          donorPostalCode: manualDonation.zipCode,

          donorExist: manualDonation.donorExist,
        }
      );

      if (response.code === 201) {
        showLoader(false);
        setAlert("Don ajouté avec succès", "success");
        setManualDonation({
          paymentDate: new Date().toString(),
          amount: "",
          paymentMode: "",
          checkNum: "",
          bankName: "",
          campaign: "",
          campaignParent: "",
          campaignParentId: "",
          donorExist: "",
          firstName: "",
          lastName: "",
          email: "",
          city: "",
          country: "",
          zipCode: "",
          address: "",
          phoneNumber: "",
          company: "",
        });
        setReturnDataFromSearch(false);
      } else {
        showLoader(false);
        setAlert("Une erreur est survenue", "info");
      }
    } catch (error) {
      showLoader(false);
      setAlert("Une erreur est survenue", "error");
      console.log(error);
    }
  };

  const [manualDonation, setManualDonation] = useState({
    paymentDate: new Date().toString(),
    amount: "",
    paymentMode: "",
    checkNum: "",
    bankName: "",
    campaign: "",
    campaignParent: "",
    campaignParentId: "",
    donorExist: "",
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    country: "",
    zipCode: "",
    address: "",
    phoneNumber: "",
    company: "",
  });

  const [searchPopupOpen, setSearchPopupOpen] = useState(false);

  const [returnDataFromSearch, setReturnDataFromSearch] = useState(false);

  const handleFormChange = (e) => {
    if (e.target.name === "paymentMode") {
      if (e.target.value === "check") {
        setNumCheckDisplay(true);
        setBankNameDisplay(false);
      } else if (e.target.value === "transfer") {
        setBankNameDisplay(true);
        setNumCheckDisplay(false);
      } else {
        setNumCheckDisplay(false);
        setBankNameDisplay(false);
      }
    }

    setManualDonation({ ...manualDonation, [e.target.name]: e.target.value });
  };

  const handleDonationCampaignParentExistChange = (event) => {
    const camptoset = `${event.target.value}`;
    var setParent = false;
    campaigns.forEach((campaign) => {
      if (campaign.name === camptoset) {
        setSelectedCampaign(
          campaign.campaignParent.id + ";" + campaign.campaignParent.name
        );

        setManualDonation({
          ...manualDonation,
          campaignParent: campaign.campaignParent.name,
          campaignParentId: campaign.campaignParent.id,
          [event.target.name]: event.target.value,
        });
        setParent = true;
      }
    });

    if (!setParent) {
      setSelectedCampaign("13;Autres");
      setManualDonation({
        ...manualDonation,
        [event.target.name]: event.target.value,
        campaignParent: "Autres",
        campaignParentId: "13",
      });
    }
  };

  const handleSearchDonorCallback = (donor) => {
    if (donor) {
      setManualDonation({
        ...manualDonation,
        donorExist: donor.id,
        firstName: donor.firstName,
        lastName: donor.lastName,
        email: donor.user ? donor.user.email : "",
        city: donor.city,
        country: donor.country,
        zipCode: donor.zipCode,
        address: donor.address,
        phoneNumber: donor.phoneNumber,
        company: donor.company,
      });
    } else {
      setManualDonation({
        ...manualDonation,
        donorExist: false,
        firstName: "",
        lastName: "",
        email: "",
        city: "",
        country: "",
        zipCode: "",
        address: "",
        phoneNumber: "",
        company: "",
      });
    }
    setSearchPopupOpen(false);
    setReturnDataFromSearch(true);
  };

  return (
    <>
      {searchPopupOpen ? (
        <SearchDonorPopup searchDonorCallback={handleSearchDonorCallback} />
      ) : (
        ""
      )}

      <div style={{ width: "100%", margin: "20px auto" }}>
        <h2 className="page_title">Ajouter un nouveau don</h2>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          id="formAddNewDonation"
          onSubmit={handelSubmitDonation}
        >
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <div>
                  <h3>Informations relative au don</h3>
                  <MuiPickersUtilsProvider
                    locale={frLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date du don"
                      value={paymentDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "choisir une date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <TextField
                    required
                    id="amount"
                    name="amount"
                    type="number"
                    label="Montant"
                    value={manualDonation.amount}
                    onChange={handleFormChange}
                  />
                  <FormControl className={classes.formControl}>
                    <InputLabel id="payment_mode_id">
                      Mode de paiment
                    </InputLabel>
                    <Select
                      labelId="payment_mode_id"
                      name="paymentMode"
                      id="paymentMode"
                      value={manualDonation.paymentMode}
                      onChange={handleFormChange}
                    >
                      <MenuItem value={"cash"}>Espèce</MenuItem>
                      <MenuItem value={"check"}>Chèque</MenuItem>
                      <MenuItem value={"transfer"}>Virement</MenuItem>
                      <MenuItem value={"other"}>Autres</MenuItem>
                    </Select>
                    <TextField
                      id="transferBanque"
                      label="Banque"
                      name="bankName"
                      onBlur={handleFormChange}
                      style={{
                        display: bankNameDisplay ? "block" : "none",
                        width: "100%",
                      }}
                      size="small"
                    />
                    <TextField
                      id="checkNumber"
                      label="Numéro de chèque"
                      name="checkNum"
                      onBlur={handleFormChange}
                      style={{
                        display: numCheckDisplay ? "block" : "none",
                        width: "100%",
                      }}
                      size="small"
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <input
                      id="campaigns_suggestions-input"
                      type="text"
                      list="campaigns_suggestions"
                      className="filter_input"
                      name="campaign"
                      placeholder=""
                      onBlur={handleDonationCampaignParentExistChange}
                    />
                    <label htmlFor="campaigns_suggestions-input" className="campaigns_suggestions_label" id="campaigns_suggestions-label">
                      Campagne<span aria-hidden="true">&thinsp;*</span>
                    </label>
                    <datalist id="campaigns_suggestions">
                      {campaigns.map((campaign, index) => (
                        <option key={index}>{campaign.name}</option>
                      ))}
                    </datalist>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <InputLabel id="parent_campaign_id">
                      Campagne parent
                    </InputLabel>
                    <Select
                      labelId="parent_campaign_id"
                      id="givenCampaignName"
                      value={selectedCampaign}
                      disabled={true}
                    >
                      {parentCampaigns.map((val, index) => (
                        <MenuItem key={val.id} value={val.id + ";" + val.name}>
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Paper>
            </Grid>
            <Grid item md={6}>
              <Paper
                elevation={3}
                style={{ padding: "20px", marginBottom: "20px" }}
              >
                <div>
                  <h3>Informations relative au donateur</h3>
                  {returnDataFromSearch ? (
                    <>
                      <TextField
                        required
                        id="donator_fname"
                        label="Prénom (donateur)"
                        name="firstName"
                        value={manualDonation.firstName}
                        onChange={handleFormChange}
                      />
                      <TextField
                        required
                        id="donator_lname"
                        label="Nom (donateur)"
                        name="lastName"
                        value={manualDonation.lastName}
                        onChange={handleFormChange}
                      />
                      <TextField
                        id="donator_email"
                        type="email"
                        label="Email (donateur)"
                        name="email"
                        value={manualDonation.email}
                        onChange={handleFormChange}
                      />
                      <TextField
                        id="donorCompany"
                        label="Société (donateur)"
                        name="company"
                        value={manualDonation.company}
                        onChange={handleFormChange}
                      />
                      <TextField
                        id="donorAddress"
                        label="Adresse (donateur)"
                        name="address"
                        value={manualDonation.address}
                        onChange={handleFormChange}
                      />
                      <TextField
                        id="donorCountry"
                        label="Pays (donateur)"
                        name="country"
                        value={manualDonation.country}
                        onChange={handleFormChange}
                      />
                      <TextField
                        id="donorCity"
                        label="Ville (donateur)"
                        name="city"
                        value={manualDonation.city}
                        onChange={handleFormChange}
                      />
                      <TextField
                        id="donorPostalCode"
                        label="Code postal (donateur)"
                        name="zipCode"
                        value={manualDonation.zipCode}
                        onChange={handleFormChange}
                      />
                      <TextField
                        id="donorPhone"
                        label="Numéro de téléphone (donateur)"
                        name="phoneNumber"
                        value={manualDonation.phoneNumber}
                        onChange={handleFormChange}
                      />
                    </>
                  ) : (
                    <Button color="secondary" onClick={() => setSearchPopupOpen(true)}>
                      1 - Vérifier si le donateur Existe ou pas
                    </Button>
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Button fullWidth variant="contained" color="primary" type="submit">
            Enregistrer
          </Button>
        </form>
      </div>
    </>
  );
};

DonationCreate.propTypes = {
  showLoader: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default connect(null, { showLoader, setAlert })(DonationCreate);

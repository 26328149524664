import React, { useState, useEffect } from "react";

import { useParams } from "react-router";

const Details = () => {
  let { id } = useParams();

  const [dcUrl, setDcUrl] = useState("");

  useEffect(() => {
    setDcUrl(
      `${process.env.REACT_APP_NEW_BASEURL_APIS}/donations-certificates/${id}`
    );

    return () => {
      setDcUrl("");
    };
  }, [id]);

  return (
    <div style={{ margin: "20px auto" }}>
      {dcUrl && id ? (
        <iframe
          title="RF"
          id="pdfPreview"
          width="100%"
          height="700px"
          type="application/pdf"
          style={{ border: "none" }}
          src={dcUrl}
        >
          <p>Le PDF ne peut pas être affiché.</p>
        </iframe>
      ) : (
        <h4 align="center">
          Url du RF n'est pas valide{" "}
          <span role="img" aria-label="sry">
            😒
          </span>
        </h4>
      )}
    </div>
  );
};

export default Details;

import { Button, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";

import SimpleTable from "../../Components/SimpleTable";

import API from "../../utils/API";

const recordPerPage = 5;

const tableCells = [
  {
    label: "#",
    field: "id",
  },
  {
    label: "Nom Complet",
    field: "fullName",
  },
  {
    label: "Email",
    field: "email",
  },
  {
    label: "Actions",
    field: "actions",
  },
];
const LatestDonors = () => {
  const [donors, setDonors] = useState([]);

  useEffect(() => {
    const getDonors = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donors?pageNum=1&pageSize=${recordPerPage}`
        );

        res.data.forEach((donor) => {
          donor["actions"] = (
            <Link
              to={`/donors/${donor.id}/details`}
              style={{ color: "inherit" }}
            >
              <VisibilityIcon />
            </Link>
          );
          donor["id"] = `#${donor.id}`;
        });

        setDonors(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    getDonors();

    return () => {
      setDonors([]);
    };
  }, []);

  return (
    <Paper style={{ height: "100%" }}>
      <div className="container__inner">
        <h3 className="block_title">Derniers Donateurs</h3>
        <SimpleTable data={donors} tableCells={tableCells} />
        <div align="right" style={{ marginTop: "10px" }}>
          <Button variant="contained" color="primary" size="small">
            <Link to="/donors" style={{ color: "inherit" }}>
              Voir plus
            </Link>
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default LatestDonors;

import React, { useEffect, useState } from "react";
import SimpleTable from "../../Components/SimpleTable";
import SimplePagination from "../../Components/SimplePagination";

import { connect } from "react-redux";
import { showLoader } from "../../providers/actions/loader";
import PropTypes from "prop-types";

import { useParams, useHistory } from "react-router";

import API from "../../utils/API";

import VisibilityIcon from "@material-ui/icons/Visibility";

import "./main.css";
import { Link } from "react-router-dom";
import RFStats from "./RFStats";

const recordPerPage = 10;

const tableCells = [
  {
    label: "#",
    field: "id",
  },
  {
    label: "Montant",
    field: "amount",
    type: "currency",
  },
  {
    label: "URL",
    field: "url",
  },
  {
    label: "Année",
    field: "year",
  },
  {
    label: "Actions",
    field: "actions",
  },
];

const FiscalReceipt = ({ showLoader }) => {
  let { page } = useParams();

  let history = useHistory();

  const [pageNum, setPageNum] = useState(page ? page : 1);

  const [pagesTotal, setPagesTotal] = useState(1);

  const [rfs, setRfs] = useState([]);

  useEffect(() => {
    const getRfs = async () => {
      showLoader(true);
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/recus-fiscaux?pageNum=${pageNum}&pageSize=${recordPerPage}`
        );

        res.data.forEach((rf) => {
          rf["actions"] = (
            <Link
              to={`/fiscal-receipts/${rf.id}/details`}
              style={{ color: "inherit" }}
            >
              <VisibilityIcon />
            </Link>
          );
          rf["id"] = `#${rf.id}`;
        });

        setRfs(res.data);

        setPageNum(res.pageNum);

        setPagesTotal(Math.ceil(res.total / recordPerPage));
        showLoader(false);
      } catch (error) {
        console.log(error);
        showLoader(false);
      }
    };

    getRfs();

    return () => {
      setRfs([]);

      setPagesTotal(1);
    };
  }, [pageNum, showLoader]);

  const handlePaginationCallback = (pageNumCallback) => {
    setPageNum(pageNumCallback);

    history.push(`/fiscal-receipts/${pageNumCallback}`);
  };

  return (
    <>
      <RFStats />
      <div style={{ width: "100%", margin: "20px auto" }}>
        <h1 className="page_title">Historiques des RF annuel</h1>
        <SimpleTable data={rfs} tableCells={tableCells} />
        <SimplePagination
          pageNum={pageNum}
          pagesTotal={pagesTotal}
          paginationCallback={handlePaginationCallback}
        />
      </div>
    </>
  );
};

FiscalReceipt.propTypes = {
  showLoader: PropTypes.func.isRequired,
};

export default connect(null, { showLoader })(FiscalReceipt);

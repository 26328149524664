import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { showLoader } from "../../providers/actions/loader";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import VisibilityIcon from "@material-ui/icons/Visibility";
import SimplePagination from "../../Components/SimplePagination";
import { useParams, useHistory } from "react-router";
import SimpleTable from "../../Components/SimpleTable";

import { Grid, Button } from "@material-ui/core";

import API from "../../utils/API";

const headCells = [
  {
    label: "#",
    field: "id",
  },
  {
    field: "amount",
    label: "montant",
    type: "currency",
  },
  {
    field: "geteway",
    label: "Passerelle",
  },
  {
    field: "fullName",
    label: "Donateur",
  },
  {
    field: "company",
    label: "raison social",
  },
  {
    field: "paymentDate",
    label: "Date",
    type: "date",
  },
  {
    label: "Actions",
    field: "actions",
  },
];

const recordPerPage = 10;

const Companies = ({ showLoader }) => {
  let { page } = useParams();

  let history = useHistory();

  const [conpaniesDonations, setConpaniesDonations] = useState([]);

  const [pageNum, setPageNum] = useState(page ? page : 1);

  const [pagesTotal, setPagesTotal] = useState(1);

  useEffect(() => {
    showLoader(true);
    const getData = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/companies/donations?pageNum=${pageNum}&pageSize=${recordPerPage}`
        );

        res.data.forEach((donations) => {
          donations["actions"] = (
            <Link to={`/donation/${donations.id}`} style={{ color: "inherit" }}>
              <VisibilityIcon />
            </Link>
          );
        });

        setConpaniesDonations(res.data);

        setPageNum(res.pageNum);

        setPagesTotal(Math.ceil(res.total / recordPerPage));

        showLoader(false);
      } catch (error) {
        console.log(error);

        showLoader(false);
      }
    };

    getData();
    return () => {
      setConpaniesDonations([]);
    };
  }, [pageNum, showLoader]);

  const handlePaginationCallback = (pageNumCallback) => {
    setPageNum(pageNumCallback);

    history.push(`/companies-donations/${pageNumCallback}`);
  };

  const handleExportClick = async () => {
    showLoader(true);
    try {
      const response = await API(
        "GET",
        `${process.env.REACT_APP_API_BASE_URL}/companies/donations/export`
      );

      const jsonRes = response.data;

      // var encodedUri = encodeURI(csvContent);
      let csvContent = atob(jsonRes);
      var blob = new Blob([csvContent], {
        type: "data:text/csv;charset=utf-8",
      });
      var url = window.URL.createObjectURL(blob);

      var link = document.createElement("a");
      link.setAttribute("href", url);
      // console.log("data:text/csv;charset=utf-8;base64" + jsonRes);
      link.setAttribute(
        "download",
        "Companies-Donations-" + "-" + (new Date().getFullYear() - 1) + ".csv"
      );
      document.body.appendChild(link); // Required for FF

      link.click();
    } catch (error) {
      console.log("ERROR in export msg: " + error);
      showLoader(false);
    }
    showLoader(false);
  };

  return (
    <div style={{ width: "100%", margin: "20px auto" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h1 className="page_title">Entreprises Dons</h1>
        <Button variant="contained" color="primary" onClick={handleExportClick}>
          exporter les dons de l'année {new Date().getFullYear() - 1}
        </Button>
      </Grid>
      <p>
        Les dons des entreprises se sont les dons ayant le champs "raison
        sociale" non vide.
      </p>

      <div>
        <h2>Liste des Dons {new Date().getFullYear()}</h2>
        <SimpleTable data={conpaniesDonations} tableCells={headCells} />

        <SimplePagination
          pageNum={pageNum}
          pagesTotal={pagesTotal}
          paginationCallback={handlePaginationCallback}
        />
      </div>
    </div>
  );
};
Companies.propTypes = {
  showLoader: PropTypes.func.isRequired,
};
export default connect(null, { showLoader })(Companies);

import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import "./main.css";
import { Paper } from "@material-ui/core";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import GroupIcon from "@material-ui/icons/Group";

const GlobalNumbers = () => {
  const [totalAmount, setTotalAmount] = useState({
    totalThisYear: 0,
    totalLastYear: 0,
  });
  const [totalDonors, setTotalDonors] = useState({
    totalThisYear: 0,
    totalLastYear: 0,
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_NEW_BASEURL_APIS}/dashboard/numbers`
        );
        const numbers = response.data;

        var nf = Intl.NumberFormat();
        // var x = 42000000;
        // console.log(nf.format(x));

        setTotalAmount({
          totalThisYear: nf.format(numbers.actualYear.donations),
          totalLastYear: nf.format(numbers.lastYear.donations),
        });
        setTotalDonors({
          totalThisYear: numbers.actualYear.donors,
          totalLastYear: numbers.lastYear.donors,
        });
      } catch (error) {
        console.log(error);
      }
    };
    getData();
    return () => {
      setTotalAmount({
        totalThisYear: 0,
        totalLastYear: 0,
      });
      
      setTotalDonors({
        totalThisYear: 0,
        totalLastYear: 0,
      });
    };
  }, []);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "1em",
        marginBottom: "1em",
      }}
    >
      <div className="actual_year">
        <div className="dashboard__year">{new Date().getFullYear()}</div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1em",
          }}
        >
          <Paper
            elevation={3}
            style={{
              display: "flex",
              height: "100%",
              widh: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <EuroSymbolIcon fontSize="large" />
            <span>{totalAmount.totalThisYear} €</span>
          </Paper>
          <Paper
            elevation={3}
            style={{
              display: "flex",
              height: "100%",
              widh: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <GroupIcon fontSize="large" />
            <span>{totalDonors.totalThisYear}</span>
          </Paper>
        </div>
      </div>
      <div className="last_year">
        <div className="dashboard__year">{new Date().getFullYear() - 1}</div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1em",
          }}
        >
          <Paper
            elevation={3}
            style={{
              display: "flex",
              height: "100%",
              widh: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <EuroSymbolIcon fontSize="large" />
            <span>{totalAmount.totalLastYear} €</span>
          </Paper>
          <Paper
            elevation={3}
            style={{
              display: "flex",
              height: "100%",
              widh: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <GroupIcon fontSize="large" />
            <span>{totalDonors.totalLastYear}</span>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default GlobalNumbers;

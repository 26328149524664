import React, { useState, useEffect } from "react";
import SimplePagination from "../../Components/SimplePagination";
import SimpleTable from "../../Components/SimpleTable";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import API from "../../utils/API";

import "./main.css";

const recordPerPage = 10;

const tableCells = [
  {
    label: "#",
    field: "id",
  },
  {
    label: "Montant",
    field: "amount",
    type: "currency",
  },
  {
    label: "Passerelle",
    field: "geteway",
  },
  {
    label: "Demandé par",
    field: "reaquestedBy.name",
  },
  {
    label: "Demande Date",
    field: "createdAt",
    type: "date",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Actions",
    field: "actions",
  },
];

const RefundRequests = () => {
  const [pageNum, setPageNum] = useState(1);
  const [pagesTotal, setPagesTotal] = useState(1);

  const handlePaginationCallback = (pageNum) => {
    setPageNum(pageNum);
  };

  const [refundRequests, setRefundRequests] = useState([]);

  useEffect(() => {
    const getRefundRequests = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/refund-requests?pageNum=${pageNum}&pageSize=${recordPerPage}`
        );

        res.data.forEach((rRequest) => {
          rRequest["actions"] = (
            <Link
              to={`/refund-requests/${rRequest.id}/details`}
              style={{ color: "inherit" }}
            >
              <VisibilityIcon />
            </Link>
          );

          rRequest["id"] = `#${rRequest.id}`;

          rRequest["status"] = (
            <span
              className={`status__badge ${rRequest.status.replace(" ", "_")}`}
            >
              {rRequest.status}
            </span>
          );
        });

        setRefundRequests(res.data);

        setPageNum(res.pageNum);

        setPagesTotal(Math.ceil(res.total / recordPerPage));
      } catch (error) {
        console.log(error);
      }
    };

    getRefundRequests();

    return () => {
      setRefundRequests([]);

      setPagesTotal(1);
    };
  }, [pageNum]);

  return (
    <div style={{ widh: '100%', margin: "20px auto" }}>
      <h1 className="page_title">Demandes de remboursement</h1>
      <div>
        <SimpleTable data={refundRequests} tableCells={tableCells} />

        <SimplePagination
          pageNum={pageNum}
          pagesTotal={pagesTotal}
          paginationCallback={handlePaginationCallback}
        />
      </div>
    </div>
  );
};

export default RefundRequests;

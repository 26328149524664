import React, { useState, useEffect } from "react";

import {
  Grid,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress, Paper
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Backdrop from "@material-ui/core/Backdrop";

import API from "../../utils/API";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "17px 0 8px",
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  datePicker: {
    width: "100%",
  },
}));

const formatDate = (date) => {
  let dateToFormat = new Date(date);
  let dd = dateToFormat.getDate();
  let mm = dateToFormat.getMonth() + 1;

  let yyyy = dateToFormat.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "-" + mm + "-" + yyyy;
};

const Exporter = () => {
  const classes = useStyles();
  const [parentCampaigns, setParentCampaigns] = useState([]);
  const [selectedValues, setselectedValues] = useState([]);
  const [selectedValue, setselectedValue] = useState([]);
  const [gatway, setGatway] = useState("");
  const [dateFrom, setDateFrom] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [dateTo, setDateTo] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getAllParentCampaigns = async () => {
      const response = await API(
        "GET",
        `${process.env.REACT_APP_API_BASE_URL}/campaigns-parent`
      );
      const dataJson = response.data;
      setParentCampaigns(dataJson);
    };
    getAllParentCampaigns();
  }, []);

  const handleDateFromChange = (date) => {
    setDateFrom(date);
  };
  const handleDateToChange = (date) => {
    setDateTo(date);
  };
  const handleSelectChange = (event) => {
    setselectedValues(event.target.value);
  };

  const handleSelectSingleChange = (event) => {

    setselectedValue(event.target.value);
  }
  const handleGatwayChange = (event) => {
    setGatway(event.target.value);
  };

  const submitHandler = () => {
    expoterHandler(dateFrom, dateTo);
  };

  const expoterHandler = async (dateFrom, dateTo) => {
    setOpen(true);
    const dateFromFormated = formatDate(dateFrom);
    const dateToFormated = formatDate(dateTo);

    const response = await API(
      "POST",
      `${process.env.REACT_APP_API_BASE_URL}/export`,
      {
        fromDate: dateFromFormated,
        toDate: dateToFormated,
        campaignids: selectedValues,
        passerelle: gatway,
      }
    );

    const jsonRes = response.data;

    // var encodedUri = encodeURI(csvContent);
    let csvContent = atob(jsonRes);
    var blob = new Blob([csvContent], {
      type: "data:text/csv;charset=utf-8",
    });
    var url = window.URL.createObjectURL(blob);

    var link = document.createElement("a");
    link.setAttribute("href", url);
    // console.log("data:text/csv;charset=utf-8;base64" + jsonRes);
    link.setAttribute(
      "download",
      "Donations-" +
        formatDate(new Date()) +
        "-" +
        new Date().getHours() +
        "-" +
        new Date().getMinutes() +
        ".csv"
    );
    document.body.appendChild(link); // Required for FF

    link.click();
    setOpen(false);
  };

  const handleSubmitByDay = async () => {
    setOpen(true);
    const dateFromFormated = formatDate(dateFrom);
    const dateToFormated = formatDate(dateTo);

    const response = await API(
      "GET",
      `${process.env.REACT_APP_API_BASE_URL}/export/byday/${dateFromFormated}/${dateToFormated}/${selectedValue}`
    );

    const jsonRes = response.data;

    // var encodedUri = encodeURI(csvContent);
    let csvContent = atob(jsonRes);
    var blob = new Blob([csvContent], {
      type: "data:text/csv;charset=utf-8",
    });
    var url = window.URL.createObjectURL(blob);

    var link = document.createElement("a");
    link.setAttribute("href", url);
    // console.log("data:text/csv;charset=utf-8;base64" + jsonRes);
    link.setAttribute(
      "download",
      "Donations-" +
        formatDate(new Date()) +
        "-" +
        new Date().getHours() +
        "-" +
        new Date().getMinutes() +
        ".csv"
    );
    document.body.appendChild(link); // Required for FF

    link.click();
    setOpen(false);
  };

  return (
    <div style={{ width: "95%", margin: "2px auto" }}>
      <h1 className="page_title">Export</h1>

      <Paper style={{padding: "10px 20px"}}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="baseline"
          spacing={2}
        >
          <Grid container item md={12} sm={12} xs={12}>
            
            <Grid  item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline-1"
                  label="Date de début"
                  value={dateFrom}
                  onChange={handleDateFromChange}
                  KeyboardButtonProps={{
                    "aria-label": "choisir une date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid  item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline-2"
                  label="Date de fin"
                  value={dateTo}
                  onChange={handleDateToChange}
                  KeyboardButtonProps={{
                    "aria-label": "choisir une date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid  item md={6} sm={6} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-helper-label">
                  Campagne
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper-label"
                  multiple
                  value={selectedValues}
                  onChange={handleSelectChange}
                  MenuProps={{ PaperProps: { style: { maxHeight: "200px" } } }}
                >
                  <MenuItem key={"All"} value="-1">
                    All
                  </MenuItem>
                  {parentCampaigns.map((val, index) => (
                    <MenuItem key={val.id} value={val.id}>
                      {val.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="gatway-simple-select-helper-label">
                  Passerelle
                </InputLabel>
                <Select
                  labelId="gatway-simple-select-helper-label"
                  id="gatway-simple-select-helper-label"
                  value={gatway}
                  onChange={handleGatwayChange}
                  MenuProps={{ PaperProps: { style: { maxHeight: "200px" } } }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="stripe">Stripe</MenuItem>
                  <MenuItem value="paypal">Paypal</MenuItem>
                  <MenuItem value="gocardless">Gocardless</MenuItem>
                  <MenuItem value="stripe-iraiser">Stripe IRAISER</MenuItem>
                  <MenuItem value="Chèque">Chèque </MenuItem>
                  <MenuItem value="Espèce">Espèce </MenuItem>
                  <MenuItem value="Virement">Virement </MenuItem>
                </Select>
              </FormControl>
            </Grid>

          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" item md={12} sm={12} xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={submitHandler}
          >
            Exporter
          </Button>
        </Grid>
      </Paper>

      <h1 className="page_title">Export par jours</h1>

      <Paper style={{padding: "10px 20px"}}>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="baseline"
          spacing={2}
        >
          <Grid container item md={12} sm={12} xs={12}>


            <Grid  item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline-1"
                  label="Date de début"
                  value={dateFrom}
                  onChange={handleDateFromChange}
                  KeyboardButtonProps={{
                    "aria-label": "choisir une date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid  item md={6} sm={6} xs={12}>
              <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline-2"
                  label="Date de fin"
                  value={dateTo}
                  onChange={handleDateToChange}
                  KeyboardButtonProps={{
                    "aria-label": "choisir une date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid  item md={12} sm={12} xs={12}>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-helper-label">
                  Campagne
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper-label"
                  
                  value={selectedValue}
                  onChange={handleSelectSingleChange}
                  MenuProps={{ PaperProps: { style: { maxHeight: "200px" } } }}
                >
                  <MenuItem key={"All"} value="-1">
                    All
                  </MenuItem>
                  {parentCampaigns.map((val, index) => (
                    <MenuItem key={val.id} value={val.id}>
                      {val.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" item md={12} sm={12} xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmitByDay}
          >
            Exporter
          </Button>
        </Grid>
      </Paper>

      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Exporter;

import React from "react";
import DonorByYear from "./DonorByYear";

import LastMonthNumbers from "./LastMonthNumbers";
import GlobalNumbers from "./GlobalNumbers";
import LatestDonors from "./LatestDonors";
import LatestDonations from "./LatestDonations";

function Dashboard({ history }) {
  return (
    <div className="container__page">
      <GlobalNumbers />
      <div className="container__page__inner">
        <LatestDonations />
        <LastMonthNumbers />
      </div>

      <div className="container__page__inner">
        <DonorByYear />
        <LatestDonors />
      </div>
    </div>
  );
}
export default Dashboard;

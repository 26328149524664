import React, { useState, useEffect } from "react";

import { Grid, Paper } from "@material-ui/core";

import imageRepas from "../../assets/images/1e1repas.png";
import imagePuits from "../../assets/images/puits.png";
import imageSapousse from "../../assets/images/sapousse.png";

import API from "../../utils/API";

const LastMonthNumbers = () => {
  const [repas, setRepas] = useState(0);
  const [arbres, setArbres] = useState(0);
  const [puits, setPuits] = useState(0);

  useEffect(() => {
    const getLastMonthNumbers = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_NEW_BASEURL_APIS}/dashboard/projects`
        );
        const numbers = response.data;

        // for formatting numbers
        var nf = Intl.NumberFormat();

        setRepas(nf.format(numbers.meals));
        setArbres(nf.format(numbers.trees));
        setPuits(nf.format(numbers.wells));
      } catch (error) {
        console.log(error);
      }
    };
    getLastMonthNumbers();
    return () => {
      setRepas(0);
      setArbres(0);
      setPuits(0);
    };
  }, []);
  return (
    <Paper style={{ height: "100%" }}>
      <div className="container__inner">
        <h3 className="block_title">Le dernier mois</h3>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
          style={{ marginBottom: "40px", marginTop: "40px" }}
        >
          <Grid item md={6} sm={6} xs={12}>
            <Paper
              elevation={3}
              style={{
                padding: "10px 0",
                borderBottom: "solid 5px rgb(215, 63, 64)",
              }}
            >
              <div className="last_month__item__container">
                <div>
                  <p>{repas}</p>
                  <p>
                    <b>Repas</b>
                  </p>
                </div>
                <img
                  src={imageRepas}
                  alt="1e1repas"
                  style={{ maxHeight: "50px" }}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
            <Paper
              elevation={3}
              style={{
                padding: "10px 0",
                borderBottom: "solid 5px rgb(135, 158, 48)",
              }}
            >
              <div className="last_month__item__container">
                <div>
                  <p>{arbres}</p>
                  <p>
                    <b>Arbres</b>
                  </p>
                </div>
                <img
                  src={imageSapousse}
                  alt="sapousse"
                  style={{ maxHeight: "50px" }}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item md={6} sm={6} xs={12} style={{ textAlign: "center" }}>
            <Paper
              elevation={3}
              style={{
                padding: "10px 0",
                borderBottom: "solid 5px rgb(112, 178, 245)",
              }}
            >
              <div className="last_month__item__container">
                <div>
                  <p>{puits}</p>
                  <p>
                    <b>Puits</b>
                  </p>
                </div>
                <img
                  src={imagePuits}
                  alt="WaterSponsor"
                  style={{ maxHeight: "50px" }}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default LastMonthNumbers;
